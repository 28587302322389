import { Box, Button, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface PDFToolbarProps {
  currentPage: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  position?: 'top' | 'bottom';
}

export const PDFToolbar: React.FC<PDFToolbarProps> = ({
  currentPage,
  totalPages,
  onPreviousPage,
  onNextPage,
  position = 'top',
}) => {
  const { t } = useTranslation();
  const borderRadius = position === 'top' ? '8px 8px 0 0' : '0 0 8px 8px';

  return (
    <Box
      style={{
        padding: '8px',
        background: '#f5f5f5',
        position: 'sticky',
        borderRadius,
        bottom: 0,
      }}
    >
      <Group justify="center" gap="sm">
        <Button
          size="xs"
          variant="light"
          onClick={onPreviousPage}
          disabled={currentPage <= 1}
        >
          {t('pdf.previous')}
        </Button>
        <Text size="xs">
          {t('pdf.pageCount', {
            current: currentPage,
            total: totalPages,
          })}
        </Text>
        <Button
          size="xs"
          variant="light"
          onClick={onNextPage}
          disabled={currentPage >= totalPages}
        >
          {t('pdf.next')}
        </Button>
      </Group>
    </Box>
  );
};
